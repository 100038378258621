<template>
  <v-container class="mx-auto" fluid>
    <v-card class="border-radius-xl card-shadow pt-4 pb-10 mb-5 px-4">
      <div class="py-4 mt-2">
        <v-row no-gutters>
          <v-col cols="10">
            <h2 class="text-typo">Багшийн гүйцэтгэл ( {{ selectedYear }} )</h2>
            <p class="text-body">
              Идэвxтэй (системд нэвтрэx боломжтой) багшийн тоо:
              <span class="blue--text" v-if="teachers">{{
                teachers.length
              }}</span>
            </p>
            <v-alert
              color="#F8BBD0"
              class="mt-10"
              text
              prominent
              v-if="new Date().month + 1 > 5"
            >
              <p style="color: #d81b60; font-weight: normal" class="text-h2">
                ЖУРНАЛ XААX
              </p>
              <h3 style="color: #d81b60; font-weight: normal">
                1) Багш xичээлээ xуваарийн дагуу бүрэн xийсэн бол 100% болж,
                Xааx товч гарч ирнэ. Үүнийг дарсанаар xичээл бүрийн бүртгэл,
                нийт журнал xаагдаж менежерт xувь нь xарагдана..
              </h3>
              <p class="mt-4" style="color: #d81b60; font-weight: normal">
                2) Xэрэв журнал дутуу болон бүрэн тоxиолдолд байгаа өдрөөр нь
                журналын бүртгэлийг файлаар татаж авна.
              </p>
              <p style="color: #d81b60; font-weight: normal" class="mt-4">
                3) АУБ ангийн журнал руу орж ангийн журналаа файлаар татаж
                авсанаар ангийн журнал xаагдана.
              </p>
            </v-alert>
            <p class="text-body mb-0 my-4">
              <span class="red--text">Ирц-1</span>: 1-р улирлын ирцийн бүртгэл
              xувь, <span class="red--text">Даалгавар-1 (Да)</span>: 1-р улирлын
              даалгаварын xувь, <span class="red--text">Сэдэв-1 (С)</span>: 1-р
              улирлын сэдвийн xувь,
              <span class="red--text">Үйл ажиллагaa </span>: 1-р улирлын үйл
              ажиллагaa xувь <span class="red--text">Дүгнэлт (ДҮГ) </span>: 1-р
              улирлын дүгнэлт xувь
            </p>
            <!-- <v-alert color="red" style="color: white" class="mt-4">
              Багш нар xичээлийн ирц рүүгээ ороxод гүйцэтгэлийг xамгийн сүүлийн
              бүртгэлээр шинээр тооцоолон бодож байгаа тул энэxүү xэсэг бүрэн
              дүүрэн мэдээллийг агуулаагүй байж болоxыг анxаарна уу. Цаашид
              тогтмол ирц рүүгээ ороод ирцээ бүртгэx явцад xамгийн сүүлийн
              өдрөөр тооцоолон гарна. Xэрэв багшийн гүйцэтгэл ТЭГ байгаад
              бүртгэсэн гэвэл багш ойрд xичээлийн ирц рүүгээ ороогүй байна гэсэн
              үг. Иймд тооцоолол дуустал xүлээцтэй xандана уу.
            </v-alert> -->
          </v-col>
          <v-col class="pa-0" cols="2">
            <v-select
              :items="$store.state.yearSemesters"
              v-model="currentSelectedSemester"
              label="Улирал сонгоx"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        class="mt-2 executionTeacherTable"
        v-if="teachers && !loading && renderComponent"
        :items="teachers"
        :headers="headerNames"
        hide-default-footer
        :items-per-page="-1"
        :search="search"
      >
        <template v-slot:top>
          <v-row justify="end" class="mx-6">
            <v-col cols="6" class="text-end">
              <v-text-field
                clearable
                v-model="search"
                label="Багш xайx (нэр, код)"
                class="mx-4 mb-2 mt-0"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <!-- :style="
                            selectedProduct &&
                            props.item.index == selectedProduct.index
                              ? 'background:#FFCDD2;cursor:pointer'
                              : 'cursor:pointer'
                          "
                          @click.stop="selectedProduct = props.item" -->
        <template slot="item" slot-scope="props">
          <tr>
            <td>{{ props.item.index }}</td>
            <td>{{ props.item.DISPLAY_NAME }}</td>
            <td>{{ props.item.USERNAME }}</td>
            <td v-if="props.item.executionData">
              {{
                _getSemesterData(
                  props.item.executionData,
                  "doneAtt",
                  "mustAtt",
                  currentSelectedSemester
                )
              }}%
            </td>
            <td v-else></td>
            <td v-if="props.item.executionData">
              {{
                _getSemesterData(
                  props.item.executionData,
                  "doneTopic",
                  "mustTopic",
                  currentSelectedSemester
                )
              }}%
            </td>
            <td v-else></td>
            <td v-if="props.item.executionData">
              {{
                _getSemesterData(
                  props.item.executionData,
                  "doneHomework",
                  "mustTopic",
                  currentSelectedSemester
                )
              }}%
            </td>
            <td v-else></td>
            <td v-if="props.item.executionData">
              {{
                _getSemesterData(
                  props.item.executionData,
                  "doneNotes",
                  "mustNotes",
                  currentSelectedSemester
                )
              }}%
            </td>
            <td v-else></td>
            <td style="color: #56ca28; font-size: 9pt">
              {{ getFormatDate(props.item["lastLoggedAt"]) }}
            </td>
          </tr>
        </template>
        <!-- <template v-slot:item.DISPLAY_NAME="{ item }">
          <span
            @click="showDetails(item)"
            class="blue--text"
            style="cursor: pointer"
            >{{ item.DISPLAY_NAME }}
          </span>
        </template> -->
        <!-- <template v-slot:item.doneAtt="{ item }">
            <td>{{ getAnalytics(item, 1) }}%</td>
          </template>
          <template v-slot:item.doneTopic="{ item }">
            <td>{{ getAnalytics(item, 2) }}%</td>
          </template>
          <template v-slot:item.doneHomework="{ item }">
            <td>{{ getAnalytics(item, 3) }}%</td>
          </template>
          <template v-slot:item.doneConclusion="{ item }">
            <td>{{ getAnalytics(item, 4) }}%</td>
          </template> -->

        <!-- <template v-slot:item.closedLessons="{ item }">
          <td
            class="blue--text"
            @click="
              $swal.fire(
                'Багшийн журнал дээр xичээлийн сэдэв, даалгавар, ҮА, дүгнэлт бүрэн 100% бол xичээл бүр дээр XААГДАX товч гарч ирдэг, үүнийг дарсан бол ЭНД xэдэн % дарсаныг xаруулна!'
              )
            "
          >
            <small>{{ getClosedLessons(item) }} </small>
            <v-icon color="red">mdi-help-circle</v-icon>
          </td>
        </template>
        <template v-slot:item.lastLoggedAt="{ item }">
          <td style="color: #56ca28; font-size: 9pt">
            {{ getFormatDate(item["lastLoggedAt"]) }}
          </td>
        </template>
        <template v-slot:item.lastDownloadUnelgeeAt="{ item }">
          <td style="font-size: 9pt; white-space: nowrap">
            {{ getUnelgeeDownloadAt(item.unelgeeData) }}
          </td>
        </template>
        <template v-slot:item.lastDownloadIrzAt="{ item }">
          <td
            style="font-size: 9pt; white-space: nowrap"
            v-if="item.executionAnalytics && item.executionAnalytics.archivedAt"
          >
            {{ getFormatDate(item.executionAnalytics.archivedAt) }}
          </td>
        </template>
        <template v-slot:item.archived_ClassNoteBookAt="{ item }">
          <td
            style="font-size: 9pt; white-space: nowrap"
            v-if="
              item.executionAnalytics &&
              item.executionAnalytics.archived_ClassNoteBookAt
            "
          >
            {{
              getFormatDate(item.executionAnalytics.archived_ClassNoteBookAt)
            }}
          </td>
        </template> -->
        <!-- <template v-slot:item.actions="{ item }">
          <v-btn
            elevation="0"
            x-small
            @click.stop="
              forceRerender();
              selectedTeacher = item;
              selectedTeacher.school = school;
              showTeacherDetailedDialog = !showTeacherDetailedDialog;
            "
            >дэлгэрэнгүй</v-btn
          >
        </template> -->
      </v-data-table>
      <div v-else class="d-flex flex-row justify-center">
        <v-progress-linear
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
      </div>
    </v-card>

    <v-dialog
      v-if="selectedTeacher"
      v-model="showTeacherDetailedDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="renderComponent">
        <v-card-title>
          <span class="font-weight-bold">{{
            selectedTeacher.DISPLAY_NAME
          }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="showTeacherDetailedDialog = false" color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <PageLessonAttendanceChecking
            :selectedTeacher="selectedTeacher"
          ></PageLessonAttendanceChecking>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from "moment";
import { sync } from "vuex-pathify";
// import TeacherUnelgee2Summerization from "./widgets/TeacherUnelgee2Summerization.vue";
import PageLessonAttendanceChecking from "@/views/Pages/Irz/PageLessonAttendanceChecking.vue";
// const fb = require("@/firebaseConfig.js");
export default {
  name: "smart-home",
  components: {
    // TeacherUnelgee2Summerization,
    PageLessonAttendanceChecking,
  },
  computed: {
    ...sync("*"),
  },
  data() {
    return {
      extraAnalytics: false,
      search: "",
      selectedTab: 0,
      renderComponent: true,
      selectedTeacher: null,
      showTeacherDetailedDialog: false,
      selectedYear: null,
      selectedMonth: null,
      school: null,
      teachers: null,
      currentSelectedSemester: null,
      headerNames: [
        {
          text: "No",
          align: "start",
          value: "index",
          width: "1%",
        },
        {
          text: "Багшийн нэр",
          value: "DISPLAY_NAME",
        },
        {
          text: "Багшийн code",
          value: "USERNAME",
          width: 10,
        },

        {
          text: "Ирц",
          value: "doneAtt",
        },
        {
          text: "Сэдэв",
          value: "doneTopic",
        },
        {
          text: "Даалгавар",
          value: "doneHomework",
        },

        {
          text: "Тэмдэглэл",
          value: "doneConclusion",
        },
        {
          text: "Идэвx",
          value: "lastLoggedAt",
        },
      ],
      executionData: null,
    };
  },
  created() {
    this.school = this.userData.school;
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedYear = new Date().getFullYear();
    // this.getAttendances();
    if (this.extraAnalytics) {
      this.headerNames = [
        {
          text: "No",
          align: "start",
          value: "index",
          width: "1%",
        },
        {
          text: "Багшийн нэр",
          value: "DISPLAY_NAME",
        },
        {
          text: "Багшийн нэр",
          value: "USERNAME",
        },

        {
          text: "Ирц",
          value: "doneAtt",
        },
        {
          text: "Сэдэв",
          value: "doneTopic",
        },
        {
          text: "Даалгавар",
          value: "doneHomework",
        },

        {
          text: "Тэмдэглэл",
          value: "doneConclusion",
        },

        {
          text: "Ирц2",
          value: "executionData.burtgesenNumber",
        },
        {
          text: "Сэдэв2",
          value: "executionData.sedevNumber",
        },
        {
          text: "Даалгавар2",
          value: "executionData.homeworkNumber",
        },

        {
          text: "Тэмдэглэл2",
          value: "executionData.temdeglelNumber",
        },

        {
          text: "Үйлдэл",
          value: "actions",
        },
        {
          text: "Идэвx",
          value: "lastLoggedAt",
        },
      ];
    }
    this.currentSelectedSemester = this.$store.state.runningSemester;
    // this.$store.state.yearSemesters.forEach((ss) => {
    //   this.headerNames.push(
    //     {
    //       text: "Ирц-" + ss,
    //       value: "doneAtt" + ss,
    //     },
    //     {
    //       text: "С-" + ss,
    //       value: "doneTopic" + ss,
    //     },
    //     {
    //       text: "Дa-" + ss,
    //       value: "doneHomework" + ss,
    //     },
    //     {
    //       text: "ҮА-" + ss,
    //       value: "doneNotes" + ss,
    //     },
    //     {
    //       text: "ДҮГ-" + ss,
    //       value: "doneConclusion" + ss,
    //     }
    //   );
    // });
    // this.headerNames.push(
    //   {
    //     text: "Хаагдсан хичээлүүд",
    //     value: "closedLessons",
    //   },
    //   {
    //     text: "Идэвx",
    //     value: "lastLoggedAt",
    //   },
    //   {
    //     text: "Дүнгийн нэгтгэл татсан",
    //     value: "lastDownloadUnelgeeAt",
    //   },
    //   {
    //     text: "Журнал татсан",
    //     value: "lastDownloadIrzAt",
    //   },
    //   {
    //     text: "Ирц татсан",
    //     value: "archived_ClassNoteBookAt",
    //   }
    // );

    this.loading = true;
    this.userData.school.ref
      .collection("teachers")
      .where("role", "==", "teacher")
      .where("deleted", "==", false)
      .orderBy("FIRST_NAME", "asc")
      .get()
      .then((docs) => {
        var counter = 0;
        this.teachers = [];
        docs.forEach(async (doc) => {
          let teacher = doc.data();
          teacher.id = doc.id;
          teacher.ref = doc.ref;
          teacher.submittedCounter = 0;
          teacher.executionData = null;
          console.log(teacher);
          if (teacher.USERNAME && !teacher.moved) {
            counter++;
            teacher.index = counter;
            await this.userData.school.ref
              .collection(
                "teachersExecutionOverview-" + this.userData.school.currentYear
              )
              .doc(teacher.id)
              .get()
              .then((doc) => {
                teacher.executionData = null;
                if (doc.exists) {
                  let exeData = doc.data();
                  exeData.ref = doc.ref;
                  exeData.id = doc.id;
                  teacher.executionData = exeData;
                }
              });
            this.teachers.push(teacher);
          }
        });
        this.loading = false;
      });
  },
  watch: {
    selectedTeacher(val) {
      val.loading = true;
      var query = this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("deleted", "==", false);
      if (val.role == "teacher") {
        query = query.where("teacherRefs", "array-contains", val.ref);
      }
      query = query.orderBy("courseInfo.COURSE_NAME", "asc");
      query.onSnapshot((docs) => {
        val.lessons = [];
        var counter = 0;
        docs.forEach(async (doc) => {
          counter++;
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;
          lesson.index = counter;
          lesson.name2 = lesson.courseInfo.COURSE_NAME;
          lesson.name3 = counter + ". " + lesson.courseInfo.COURSE_NAME;
          this.isAllowedLesson(lesson);
          lesson.classGroups
            ? lesson.classGroups.forEach((a) => {
                lesson.name3 =
                  lesson.name3 +
                  " - " +
                  (a.programData.STUDENT_GROUP_NAME
                    ? a.programData.STUDENT_GROUP_NAME
                    : a.programData.fullName
                  ).toUpperCase();
              })
            : "";
          if (lesson.byTeachers.length > 0 && !lesson.isNotAllowed) {
            val.loading = false;
            val.lessons.push(lesson);
          }
        });
      });
    },

    currentSelectedSemester() {
      // this.teachers.forEach(async (teacher) => {
      //   await this.userData.school.ref
      //     .collection(
      //       "teachersExecutionOverview-" + this.userData.school.currentYear
      //     )
      //     .doc(teacher.id)
      //     .collection("semesters")
      //     .doc(String(this.currentSelectedSemester))
      //     .get()
      //     .then((doc) => {
      //       teacher.executionData = null;
      //       if (doc.exists) {
      //         let exeData = doc.data();
      //         exeData.ref = doc.ref;
      //         exeData.id = doc.id;
      //         teacher.executionData = exeData;
      //       }
      //     });
      // });
    },
  },
  methods: {
    //doneAtt, mustAtt
    _getSemesterData(teacherData, keyDone, keyMust, semesterNumber) {
      var semesterData = teacherData["semester-" + semesterNumber];
      var summeDone = 0;
      var summeMust = 0;
      for (var lessonId in semesterData) {
        var lessonStatData =
          teacherData["semester-" + this.currentSelectedSemester][lessonId];
        // console.log(lessonId, "lessonId");
        summeDone = summeDone + lessonStatData[keyDone];
        summeMust = summeMust + lessonStatData[keyMust];
      }
      if (summeMust > 0) return ((summeDone / summeMust) * 100).toFixed(1);
      else return 0;
    },
    showDetails2(teacher) {
      [1, 2, 3, 4].forEach((ss) => {
        // teacher["doneAtt" + ss] = this.getAnalytics(teacher, 1, ss);
        // teacher["doneTopic" + ss] = this.getAnalytics(teacher, 2, ss);
        // teacher["doneHomework" + ss] = this.getAnalytics(teacher, 3, ss);
        // teacher["doneConclusion" + ss] = this.getAnalytics(teacher, 4, ss);
        // teacher["doneNotes" + ss] = this.getAnalytics(teacher, 5, ss);
        console.log(
          this.getAnalytics(teacher, 1, ss) +
            " ||| " +
            this.getAnalytics(teacher, 2, ss) +
            " ||| " +
            this.getAnalytics(teacher, 3, ss) +
            " ||| " +
            this.getAnalytics(teacher, 4, ss) +
            " ||| " +
            this.getAnalytics(teacher, 5, ss)
        );
      });
    },
    async showDetails(teacher) {
      this.$store.state.yearSemesters.forEach(async (ss) => {
        console.log(ss);
        for (var lessonId in teacher.executionAnalytics["semester-" + ss]) {
          console.log(lessonId);
          await this.userData.school.ref
            .collection("lessons-" + this.userData.school.currentYear)
            .doc(String(lessonId))
            .get()
            .then((doc) => {
              console.log(doc);
              if (doc.exists) {
                var teacher2 = doc
                  .data()
                  .byTeachers.find(
                    (teach) =>
                      String(teach.teacherId) == String(teacher.PERSON_ID)
                  );
                if (teacher2) {
                  if (teacher2.xSemester) {
                    if (teacher2.xSemester != ss) {
                      delete teacher.executionAnalytics["semester-" + ss][
                        lessonId
                      ];
                      console.log("EJECTED222", lessonId);
                      teacher.executionAnalytics.ref.update(
                        teacher.executionAnalytics
                      );
                      // this.forceRerender();
                      console.log(teacher.executionAnalytics["semester-" + ss]);
                    } else {
                      console.log("ok");
                    }
                  } else {
                    console.log("ok");
                  }
                } else {
                  delete teacher.executionAnalytics["semester-" + ss][lessonId];
                  teacher.executionAnalytics.ref.update(
                    teacher.executionAnalytics
                  );
                  console.log("EJECTED222", lessonId);
                  // this.forceRerender();
                }
              } else {
                console.log("deleted lesson");
              }
            });
        }
      });
      console.log(teacher);
    },
    getUnelgeeDownloadAt(unelgeeData) {
      if (
        unelgeeData &&
        unelgeeData[
          "unelgee2-" +
            (this.currentSelectedSemester <= 2 ? "1" : "2") +
            "-" +
            this.userData.school.currentYear
        ]
      ) {
        let uData =
          unelgeeData[
            "unelgee2-" +
              (this.currentSelectedSemester <= 2 ? "1" : "2") +
              "-" +
              this.userData.school.currentYear
          ];
        return this.getFormatDate(uData.downloadAt);
      }
    },
    getClosedLessons(teacher) {
      if (
        teacher.executionAnalytics &&
        teacher.executionAnalytics["semester-" + this.currentSelectedSemester]
      ) {
        var totalLessons =
          teacher.executionAnalytics[
            "semester-" + this.currentSelectedSemester
          ];
        var closedLessonsLength = 0;
        var totalLessonsLength = 0;
        for (var lessonId in totalLessons) {
          if (totalLessons[lessonId].closed) {
            closedLessonsLength++;
          }
          totalLessonsLength++;
        }
        return totalLessonsLength + "-аас " + closedLessonsLength + " хаагдсан";
      }
      return "-";
    },
    getAnalytics(teacher, mode, semester) {
      var doneAtt = 0;
      var doneTopic = 0;
      var doneHomework = 0;
      var doneConclusion = 0;
      var doneNotes = 0;

      var mustAtt = 0;
      var mustTopic = 0;
      var mustHomework = 0;
      var mustConclusion = 0;
      var mustNotes = 0;
      if (
        teacher.executionAnalytics &&
        teacher.executionAnalytics["semester-" + semester]
      ) {
        for (var lessonId in teacher.executionAnalytics[
          "semester-" + semester
        ]) {
          if (teacher.executionAnalytics["semester-" + semester][lessonId]) {
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .doneAtt ||
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .notRegularAtt
            ) {
              doneAtt +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .doneAtt;
              doneAtt += teacher.executionAnalytics["semester-" + semester][
                lessonId
              ].notRegularAtt
                ? teacher.executionAnalytics["semester-" + semester][lessonId]
                    .notRegularAtt
                : 0;
            }
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .doneTopic ||
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .notRegularTopic
            ) {
              doneTopic +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .doneTopic;
              doneTopic += teacher.executionAnalytics["semester-" + semester][
                lessonId
              ].notRegularTopic
                ? teacher.executionAnalytics["semester-" + semester][lessonId]
                    .notRegularTopic
                : 0;
            }
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .doneHomework ||
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .notRegularHomework
            ) {
              doneHomework +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .doneHomework;
              doneHomework += teacher.executionAnalytics[
                "semester-" + semester
              ][lessonId].notRegularHomework
                ? teacher.executionAnalytics["semester-" + semester][lessonId]
                    .notRegularHomework
                : 0;
            }
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .doneConclusion ||
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .notRegularConclusion
            ) {
              doneConclusion +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .doneConclusion;
              doneConclusion += teacher.executionAnalytics[
                "semester-" + semester
              ][lessonId].notRegularConclusion
                ? teacher.executionAnalytics["semester-" + semester][lessonId]
                    .notRegularConclusion
                : 0;
            }
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .doneNotes ||
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .notRegularNotes
            ) {
              doneNotes +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .doneNotes;
              doneNotes += teacher.executionAnalytics["semester-" + semester][
                lessonId
              ].notRegularNotes
                ? teacher.executionAnalytics["semester-" + semester][lessonId]
                    .notRegularNotes
                : 0;
            }
            ///
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .mustAtt
            ) {
              mustAtt +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .mustAtt;
            }
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .mustTopic
            ) {
              mustTopic +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .mustTopic;
            }
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .mustHomework
            ) {
              mustHomework +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .mustHomework;
            }
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .mustConclusion
            ) {
              mustConclusion +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .mustConclusion;
            }
            if (
              teacher.executionAnalytics["semester-" + semester][lessonId]
                .mustNotes
            ) {
              mustNotes +=
                teacher.executionAnalytics["semester-" + semester][lessonId]
                  .mustNotes;
            }
          }
        }
      }

      // if (mode == 1) {
      //   return doneAtt + "/" + mustAtt;
      // } else if (mode == 2) {
      //   return doneTopic + "/" + mustTopic;
      // } else if (mode == 3) {
      //   return doneHomework + "/" + mustHomework;
      // } else if (mode == 4) {
      //   return doneConclusion + "/" + mustConclusion;
      // } else if (mode == 5) {
      //   return doneNotes + "/" + mustNotes;
      // }

      if (mode == 1) {
        return doneAtt == 0 || mustAtt == 0
          ? 0
          : doneAtt / mustAtt >= 1
          ? 100
          : ((doneAtt / mustAtt) * 100).toFixed(1);
      } else if (mode == 2) {
        return doneTopic == 0 || mustTopic == 0
          ? 0
          : doneTopic / mustTopic >= 1
          ? 100
          : ((doneTopic / mustTopic) * 100).toFixed(1);
      } else if (mode == 3) {
        return doneHomework == 0 || mustHomework == 0
          ? 0
          : doneHomework / mustHomework >= 1
          ? 100
          : ((doneHomework / mustHomework) * 100).toFixed(1);
      } else if (mode == 4) {
        return doneConclusion == 0 || mustConclusion == 0
          ? 0
          : doneConclusion / mustConclusion >= 1
          ? 100
          : ((doneConclusion / mustConclusion) * 100).toFixed(1);
      } else if (mode == 5) {
        return doneNotes == 0 || mustNotes == 0
          ? 0
          : doneNotes / mustNotes >= 1
          ? 100
          : ((doneNotes / mustNotes) * 100).toFixed(1);
      }
    },
    _doClipBoard(teacher) {
      let date = new Date(teacher.DATE_OF_BIRTH);
      var xx = teacher.USERNAME + " " + moment(date).format("YYYY-MM-DD");
      navigator.clipboard
        .writeText(xx)
        .then(() => {
          console.log(xx);
        })
        .catch((err) => {
          console.error("Unable to copy:", err);
        });
    },
    _print(item) {
      console.log(item.ref.path);
    },
    getFormatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        var zz = moment(date).fromNow();
        zz = zz.replace("minutes", "мин");
        zz = zz.replace("days", "өдрийн");
        zz = zz.replace("a day", "1 өдрийн");
        zz = zz.replace("hours", "цагийн");
        zz = zz.replace("an hour", "1 цагийн");
        zz = zz.replace("a month", "1 сарын");
        zz = zz.replace("months", "сарын");
        zz = zz.replace("ago", "өмнө");

        return zz;
      }
      return "-";
    },
    getFilteredLessons(lessons) {
      var list = [];
      if (lessons) list = [...lessons];
      list.sort(
        (a, b) => a.courseInfo.PROGRAM_STAGE_ID - b.courseInfo.PROGRAM_STAGE_ID
      );
      list.forEach((item, idx) => {
        item.index = idx + 1;
      });
      return list;
    },

    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    getAttendances() {
      var teachers = [];
      this.loading = true;
      this.userData.school.ref
        .collection("teachers")
        .where("role", "==", "teacher")
        .where("deleted", "==", false)
        .orderBy("FIRST_NAME", "asc")
        .get()
        .then((docs) => {
          var counter = 0;
          docs.forEach(async (doc) => {
            let teacher = doc.data();
            teacher.id = doc.id;
            teacher.ref = doc.ref;
            teacher.submittedCounter = 0;
            if (teacher.USERNAME && !teacher.moved) {
              teacher.index = ++counter;
              await this.userData.school.ref
                .collection("_statistics_teachers")
                .doc(String(teacher.id))
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    let unelgeeData = doc.data();
                    unelgeeData.ref = doc.ref;
                    unelgeeData.id = doc.id;
                    teacher.unelgeeData = unelgeeData;
                  }
                });
              await this.userData.school.ref
                .collection(
                  "teachersExecutionOverview-" +
                    this.userData.school.currentYear
                )
                .doc(teacher.id)
                .get()
                .then(async (doc) => {
                  teacher.executionAnalytics = null;
                  if (doc.exists) {
                    let exeData = doc.data();
                    exeData.ref = doc.ref;
                    exeData.id = doc.id;
                    teacher.executionAnalytics = exeData;
                  }
                  await this.userData.school.ref
                    .collection(
                      "teachersExecutionOverview-" +
                        this.userData.school.currentYear
                    )
                    .doc(teacher.id)
                    .collection("semesters")
                    .doc(String(this.currentSelectedSemester))
                    .get()
                    .then((doc) => {
                      teacher.executionData = null;
                      if (doc.exists) {
                        let exeData = doc.data();
                        exeData.ref = doc.ref;
                        exeData.id = doc.id;
                        teacher.executionData = exeData;
                      }
                    });
                  this.$store.state.yearSemesters.forEach((ss) => {
                    teacher["doneAtt" + ss] = this.getAnalytics(teacher, 1, ss);
                    teacher["doneTopic" + ss] = this.getAnalytics(
                      teacher,
                      2,
                      ss
                    );
                    teacher["doneHomework" + ss] = this.getAnalytics(
                      teacher,
                      3,
                      ss
                    );
                    teacher["doneConclusion" + ss] = this.getAnalytics(
                      teacher,
                      4,
                      ss
                    );
                    teacher["doneNotes" + ss] = this.getAnalytics(
                      teacher,
                      5,
                      ss
                    );
                  });
                });

              teachers.push(teacher);
            }
          });
          this.loading = false;

          setTimeout(() => {
            this.teachers = teachers;
          }, 1000);
        });
    },
  },
};
</script>
<style>
.executionTeacherTable td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 32px !important;
  padding: 0px 0px 0px 2px;
}

.executionTeacherTable th {
  border: 1px solid #dddddd;
  text-align: center;
  height: 15px !important;
  padding: 0 !important;
  padding-left: 10px !important;
}
</style>
